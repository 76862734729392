import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import Img from "gatsby-image";
import SEO from '../../components/seo';
import ImageLandscape from '../../components/sub/landscape.js';
export const frontmatter = {
  title: "Listen Up: The Innovative Power of Shutting Up",
  date: "2017-05-03",
  author: "Roberto Ramos",
  authorTitle: "Senior Vice President, Strategy and Communications of The Doneger Group",
  teaserImage: "../../../static/media/20170503-0.jpg",
  path: "2017-05-03-Listen-Up",
  teaserText: "Some of the biggest breakthroughs in business have come from the simple act of observing the consumer more empathetically and listening to their woes."
};
const layoutProps = {
  frontmatter
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>

      <SEO title="Listen Up: The Innovative Power of Shutting Up" description="Some of the biggest breakthroughs in business have come from the simple act of observing the consumer more empathetically and listening to their woes." author='Roberto Ramos' keywords={['Robots']} />
      <MDXTag name="p" components={components}>{`It seems that with the rise of both the creative economy and social media everyone has something to say. Most of us want to get some attention from those we love, like and respect. The only problem, is that as we share more, we listen less and lose our ability to nurture true connectivity. This reduces our power of influence both on the personal and professional level. This lack of connection is one of the main reasons why many a consumer industry is hurting. The first step in regaining some of this connection is simple: just shut up and listen.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`The true, disruptive unicorns of tomorrow will be businesses anchored in listening.`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Rise of the Listening Economy`}</MDXTag></MDXTag>
      <ImageLandscape imageSrc="20170503-1.jpg" caption="Amazon Echo" />
      <MDXTag name="p" components={components}>{`Some of the biggest breakthroughs in business have come from the simple act of observing the consumer more empathetically and listening to their woes. "Returning videos to a physical store and paying late fees is dumb," said everyone to a timely Netflix. "I don't want to own a car but love the flexibility and freedom it provides, on my terms," posited urban millennials everywhere to Uber and Lyft.`}</MDXTag>
      <MDXTag name="p" components={components}>{`More thoughtful listening is consistent with a broader consumer mindset around slowing down to take it all in and be more present. We see this in the rise of activities such as transcendental meditation, yoga, adult coloring books and listening to podcasts.`}</MDXTag>
      <ImageLandscape imageSrc="20170503-2.jpg" caption="Still Processing, New York Times Podcast" />
      <MDXTag name="p" components={components}>{`Listening and its pursuit of better understanding consumers is also driving some of the most exciting developments in business. Where retail is known for being pushy, conversational commerce, fueled by technology, seeks to reach the balance between the high tech and the high touch, tapping into data that can drive a personal understanding of the consumer, delivering personalized products and experiences. Leaders in this space are voice assistants such as Siri, Alexa and Amazon's new Echo home system which is delivering voice enabled seamless shopping. Artificial intelligence will play a key role in this space, turning platforms such as messaging apps into more intuitive branding and customer service interfaces.`}</MDXTag>
      <ImageLandscape imageSrc="20170503-3.jpg" caption="Apple Genius Bar" />
      <MDXTag name="p" components={components}>{`Another development is the re-emergence of next gen/old school customer service with a focus on human expertise and conversations. Think of the appeal of speaking to a friendly, knowledgeable Apple smart genius or one of those witty, spectacled Warby Parker sales reps who can walk you through your eyewear needs and then recommend a good book.`}</MDXTag>
      <MDXTag name="p" components={components}>{`For brands, here are some tips to tap into the power of listening:`}</MDXTag>
      <ImageLandscape imageSrc="20170503-4.jpg" caption="Heinz" />
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`Find ways of putting the consumer at the center of what you do`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`While big data is key, try to focus on the power of micro insights to get granular peeks into the pressure and love points of your consumer`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Look for ways of building engagement into your research models, old surveys will no longer do the trick, make sure you are getting passionate responses and feedback`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Think of ways in which your branding and marketing can spark conversations, invite your consumer to solve some of your own creative riddles`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Look for ways of making your tonality and stories more accessible, wit and brevity can be very powerful in today's stream-filled lives`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`And for you, the innovation-seeker:`}</MDXTag></MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`Without getting into trouble, find ways of eavesdropping into consumer conversations, both out in the real world (stores, etc.) and online. Push yourself to put yourself in their shoes: where are they coming from?`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Find ways to listen to different perspectives, read articles and listen to podcasts from voices outside your immediate interest level`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Try to control your instinct to provide all the right answers, listen-in during brainstorms and problem solving sessions before jumping in`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Become a better friend and colleague by providing feedback. This forces you to be more present in what the other person is saying as well as smarter and more relevant in your advice`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Learn a different language—it's a humbling and culturally enriching experience, and it forces you to fine-tune your listening skills`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Read more, and read more deeply. Get into character mode, especially through novels, to understand the hidden motivations of characters. This will help you practice empathy, key for better consumer-driven understanding and innovations`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`While being quiet might seem like a difficult task in this era when we are titillated to share, comment and like, its benefits are powerful both at the personal and professional level. For the future rests in those who can connect. With that in mind, I'll just shut up now.`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    